import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {
  HomePageRandomJoke,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';

export const homePage = (
  <Container>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HomePageCompanyLogo />
      </Grid>
      <Grid item xs={12} md={4}>
        <HomePageStarredEntities />
      </Grid>
      <Grid item xs={12} md={4}>
        <HomePageToolkit tools={[]} />
      </Grid>
      <Grid item xs={12} md={4}>
        <HomePageRandomJoke />
      </Grid>
    </Grid>
  </Container>
);
