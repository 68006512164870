/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
} from '@backstage/plugin-cost-insights';
import type {
  Cost,
  Entity,
  Group,
  MetricData,
  Project,
} from '@backstage/plugin-cost-insights-common';

export class CostInsightsClient implements CostInsightsApi {
  async getLastCompleteBillingDate(): Promise<string> {
    return '2021-01-01'; // YYYY-MM-DD
  }

  async getUserGroups(_userId: string): Promise<Group[]> {
    return [];
  }

  async getGroupProjects(_group: string): Promise<Project[]> {
    return [];
  }

  async getAlerts(_group: string): Promise<Alert[]> {
    return [];
  }

  async getDailyMetricData(
    _metric: string,
    _intervals: string,
  ): Promise<MetricData> {
    return {
      id: 'remove-me',
      format: 'number',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getGroupDailyCost(_group: string, _intervals: string): Promise<Cost> {
    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProjectDailyCost(
    _project: string,
    _intervals: string,
  ): Promise<Cost> {
    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getCatalogEntityDailyCost(
    _catalogEntityRef: string,
    _intervals: string,
  ): Promise<Cost> {
    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProductInsights(_options: ProductInsightsOptions): Promise<Entity> {
    return {
      id: 'remove-me',
      aggregation: [0, 0],
      change: {
        ratio: 0,
        amount: 0,
      },
      entities: {},
    };
  }
}
